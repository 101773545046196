* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Times New Roman", Times, serif;

}

:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

/* globals */
body {
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}

h1 {
  font-size: 3em;
  line-height: 1;
}

h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}

h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}

h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}

h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}

.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}

.button-seemore:hover {
  background-color: #cfcfcf;
}

.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}

.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}

.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}

/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 2;
}

.nav-header {
  display: flex;
  justify-content: space-between;
}

a.btn-primary.room-link {
  z-index: 3;
}

/* h1{
  font-family: 'Calibri', sans-serif;
} */
.button-seemore {
  margin-top: 15px;
  padding: 5px;
  background-color: #ffffff;

}

.single-Hotel-info {}

desc h4,
h2 {
  font-family: cursive;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

#hotel-info {
  min-width: 200px;
}

.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}

.nav-links {
  font-family: 'Calibri', sans-serif;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}

.nav-links a {
  display: block;
  text-decoration: none;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  font-size: 25px;
}

.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100px;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }

  .nav-center {
    margin: 0 auto;
    display: flex;
  }

  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }

  .nav-links a {
    margin: 0 4rem;
  }
}

/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero {
  min-height: calc(100vh - 50px);
  background: url("../assets/img/defaul.jpg") center/cover;
  display: flex;
  background-size: 100% 100%;
  align-items: center;
  justify-content: center;
  margin-top: 80px
}

header.sc-bdvvaa.fDdaJY {
  background-size: cover;
  background-position: center;
}

/* .roomsHero {
  display: flex;
  background-size: 100% 100%;
  min-height: calc(100vh - 50px);
  background: url("../assets/img/hotels/DCboutique/DCboutique.jpg") center/cover;
} */

/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.banner h1 {
  font-size: 2.5rem;
}

.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}

.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }

  .banner h1 {
    font-size: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }

  .banner h1 {
    font-size: 4rem;
  }
}

/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}

.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
}

.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}

/* end of Title */

/* services */
.services {
  padding: 1rem 0;
}

.services {
  background: var(--darkGrey);
  text-align: center;
}

.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}

.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.services h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.services p {
  width: 80%;
  margin: 0 auto;
  color: var(--mainBlack);
}

@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}

/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}

.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}

@media screen and (min-width: 776px) {
  /* .featured-rooms-center { */
  /* width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr)); */
  /* } */
}

/* @media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
} */

/* end pf featured rooms */
/* room */
.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}

.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}

.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}

.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}

.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}

.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}

.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}

.img-container:hover img {
  opacity: 0.3;
}

.img-container:hover .price-top {
  opacity: 0;
}

.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}

.room-info {
  font-family: "Times New Roman", Times, serif;
  color: #ececec;
  background-color: #222;
  text-align: center;
  font-weight: 700;
  margin: 0px;
  letter-spacing: var(--mainSpacing);
}

.room-info-title {
  font-family: "Times New Roman", Times, serif;
  color: black;
  text-align: center;
  font-weight: 900;
  margin: 0px 10px 0 0;
  font-size: 2em;
  letter-spacing: var(--mainSpacing);
}

.room-info-content {
  text-transform: capitalize;
  letter-spacing: var(1px);
  color: #ececec;
  font-weight: 400;
  margin: 10px 10px -1px 30px;
}

/* end of room  */
/* single room*/
a {
  text-decoration: none;
}

.single-room {
  padding: 5rem 0 0 0;
}

.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}

.single-room-images img {
  width: 100%;
  display: block;
}

.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}

.desc,
.info {
  margin: 1rem 0;
}

.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.desc p {
  line-height: 1.5;
}

.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}

.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}

.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}

@media screen and (min-width: 992px) {

  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }

  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }

  .info {
    padding-left: 3rem;
  }
}

/* end of single room*/
/* roomList */
.roomslist {
  padding: 5rem 8rem;
}

.singleroom-list {
  margin-bottom: 2rem;
  padding: 2rem;
}

.roomservice {
  border-bottom: 0.5px solid #cccc;
  border-top: 0.5px solid #cccc;
  justify-content: space-around;
  display: flex;
  margin: 5rem 15rem;
  padding: 15px
}

.roomslist-style {
  padding: 2rem 8rem;
}

p#content {
  color: #222;
  padding: 0 10px 0 10px;
  font-size: 14px;
}

.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

.col {
  align-self: center;
}

.layout-container {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 2px solid rgb(255 255 255 / 80%)
}

.col-4,
.col-8 {
  padding: 0rem;
}

.col-4,
.col-md,
.col-md-4 {
  align-self: center;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}

@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}

/* end of roomList */
/* rooms filter */
.filter-container {
  padding: 5rem 0;
}

.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}

.form-group {
  text-transform: capitalize;
}

.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}

.size-inputs {
  display: flex;
}

.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}

.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}

@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}

/* end of rooms filter */

/* footer style */
.footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #222;
}

.footer h2,
p {
  color: white;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: red;
  text-decoration: underline;
  transition: var(--mainTransition);
  -webkit-transition: var(--mainTransition);
  -moz-transition: var(--mainTransition);
  -ms-transition: var(--mainTransition);
  -o-transition: var(--mainTransition);
}

.roomtitle {
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  padding-top: 50px;
  font-weight: bold;
}

/*drop down*/

.dropdown {
  display: block;
  text-decoration: none;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  font-size: 25px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 260px;
  font-size: 24px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}


.infoRoom {
  position: absolute;
  z-index: 1;
  text-align: start;
  width: 250px;
  top: 50px;
  background-color: #efefef;
  top: 40%;
  left: -50px;
  padding-top: 20px;
}
@media (max-width: 1000px) {
  .defaultHero {
    min-height: 40vh;
    background: url("../assets/img/defaul.jpg") center/cover;
    display: flex;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    margin-top: 100px
  }

  div#carouselExampleIndicators {
    margin: 0px 80px 50px 80px 
  }
}


/* Phone style */
@media (max-width: 600px) {

  .banner {
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    color: var(--mainWhite);
    padding-top: 3vh;
    margin: 0 15px 0 15px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .infoRoom {
    text-align: center;
    position: unset ;
    width: 100%;
    background-color: unset;
    padding-top: 0;
  }
  header#hero {
    padding-top: 22px;
  }

  #hotel-info img {
    min-width: 300px;
  }

  .carousel-inner div img {
    min-width: 500px;
    height: 100vw;
  }


  div#carouselExampleIndicators {
    margin: 0px 0px 50px 0px 
  }
  .img-container {
    /* width: 300px; */
    /* height: 30vh; */
  }
}

@media (min-width: 767px) {
  .phoneHeader {
    display: none;
  }

  .headerOptions {
    display: flex
  }

}

@media (min-width: 600px) {
  .carousel {
    margin-bottom: 50px;
  }

  #hotel-info img {
    min-width: 300px;
  }
 
}

@media (max-width: 1199px) {
  .roomslist-style {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .headerOptions {
    display: none;
  }

  .nav-center {
    display: contents;
  }

  .dropdown-content {
    right: 0;
  }

  .dropdown {
    font-size: 1.3em;
  }

  .nav-header h1 {
    font-size: 2em;
  }
}

.container {
  padding-top: 50px;
}

.facebook:link{
  color: black;
  font-size: larger;
}

.facebook:hover {
  color: blue;
  background-color: transparent;
  text-decoration: underline;
}